










































































































import Component from 'vue-class-component';
import Vue from 'vue';
import RsInput from '@/shared/components/fields/input.vue';
import RsButton from '@/shared/components/buttons/button.vue';
import RsIcon from '@/shared/components/icon/icon.vue';
import {Action, Getter} from 'vuex-class';
import {ReferralActions} from '@/modules/dashboard/modules/referral/store/actions';
import {ReferralGetters} from '@/modules/dashboard/modules/referral/store/getters';
import ReferralModule from '@/modules/dashboard/modules/referral';
import {IApiUserReferralData} from '@/shared/models/IApiUserReferralData';
import DashboardModule from '@/modules/dashboard';
import RsLoading from '@/shared/components/loading/loading.vue';
import {Icons} from '@/shared/components/icon/icons';

@Component({
  metaInfo: {
    title: 'Invite a friend'
  },
  components: {RsLoading, RsIcon, RsButton, RsInput}
})
export default class ReferralIndex extends Vue {
  @Action(ReferralActions.GET_DATA, {namespace: `${DashboardModule.namespace}/${ReferralModule.namespace}`}) getReferralData: () => void;
  @Getter(ReferralGetters.GET_DATA, {namespace: `${DashboardModule.namespace}/${ReferralModule.namespace}`}) data: IApiUserReferralData;

  Icons = Icons;

  copied = false;

  get referralLink(){
    if(this.data){
      return  `${process.env.VUE_APP_REFERRAL_URL}${this.data.code}`
    }
    return '';
  }

  facebookShare(){
    window.open(
      // eslint-disable-next-line vue/max-len
      'https://www.facebook.com/sharer/sharer.php?quote=Do you want to work for promising startups and companies from the US and Europe without a hassle? Remotesome offers unique and promising remote-first opportunities for software engineers.&u='+encodeURIComponent(this.referralLink),
      'facebook-share-dialog',
      'width=626,height=436');
  }
  linkedinShare(){
    window.open(
      // eslint-disable-next-line vue/max-len
      'https://www.linkedin.com/sharing/share-offsite/?summary=Do you want to work for promising startups and companies from the US and Europe without a hassle? Remotesome offers unique and promising remote-first opportunities for software engineers.&url='+encodeURIComponent(this.referralLink),
      'linkedin-share-dialog',
      'width=626,height=436');
  }

  copy(){
    navigator.clipboard.writeText(this.referralLink);
    this.copied = true;
    setTimeout(() => {
      this.copied = false;
    }, 1000)
  }

  mounted(){
    this.$track.sendEvent('ReferralPage')
    this.getReferralData();
  }

}
